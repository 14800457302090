import { Container, Box, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { RiTwitterLine } from "react-icons/ri";
import Link from "../Link";

const Footer = () => {
  return (
    <Container maxWidth="container.xl">
      <Flex paddingY={3} paddingX={2}>
        <Box>
          <Link
            color="white"
            href={`https://twitter.com/Refcheka`}
            target="_blank"
          >
            <Flex alignItems="center">
              <Icon w={18} h={18} as={RiTwitterLine} marginRight="1" /> Twitter
            </Flex>
          </Link>
        </Box>

        <Box marginLeft="auto">
          <Text color="white">&copy; Refcheka</Text>
        </Box>
      </Flex>
    </Container>
  );
};

export default Footer;
