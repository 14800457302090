import {
  Button,
  Flex,
  HStack,
  Container,
  Image,
  StackDivider,
} from "@chakra-ui/react";
import React from "react";
import { useGetCurrentUserQuery } from "../../graphql/getCurrentUser.generated";
import Link from "../Link";
import logo from "../../../../public/logo-side.svg";

const Header = () => {
  const [{ data }] = useGetCurrentUserQuery();

  return (
    <Container as="header" paddingY={5} paddingX={2} maxWidth="container.xl">
      <Flex alignItems="center" flexWrap="wrap">
        <Link
          color="white"
          width={["100%", "auto"]}
          fontSize={["large", "x-large"]}
          href="/"
        >
          <Image
            alt={"Refcheka"}
            src={logo}
            width={["250px", "250px", "250px", "250px"]}
            marginBottom={["5px", "5px", 0]}
          />
        </Link>
        <Flex flexWrap="wrap" as="nav" marginTop={[3, 0]} ml={[0, 0, "auto"]}>
          {!data?.currentUser && (
            <HStack marginBottom={["8px", "8px", 0]} paddingRight={2}>
              <Button href="/get-started" as={Link} size="sm">
                Create Account
              </Button>

              <Button href="/login" as={Link} size="sm">
                Login
              </Button>
            </HStack>
          )}

          <HStack
            divider={<StackDivider borderColor="green.700" />}
            marginInlineStart={0}
          >
            <Link color="white" href="/how-it-works">
              How it works
            </Link>

            <Link color="white" href="/pricing">
              Plans
            </Link>

            <Link color="white" href="/blog">
              Blog
            </Link>

            <Link color="white" href="/contact">
              Contact
            </Link>

            {data?.currentUser && (
              <Link color="white" href="/app">
                Dashboard
              </Link>
            )}
          </HStack>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Header;
