import {
  Box,
  Flex,
  Heading,
  Text,
  Icon,
  Button,
  Container,
  SimpleGrid,
  Stack,
  StackDivider,
} from "@chakra-ui/react";
import Head from "next/head";
import Link from "../client/components/Link";
import {
  FcApproval,
  FcCheckmark,
  FcClock,
  FcCollaboration,
  FcLike,
  FcLock,
  FcProcess,
  FcSportsMode,
} from "react-icons/fc";
import NewsletterSignup from "../client/components/NewsletterSignup";
import Footer from "../client/components/Footer";
import Header from "../client/components/Header";
import heroImage from "../../public/hero.png";
import candidateImage from "../../public/candidate.png";
import questionnairesImage from "../../public/questionnaires.png";
import reportingImage from "../../public/reporting.png";
import Image from "next/image";

const Feature = ({
  text,
  icon,
  iconBg,
}: {
  text: string;
  icon: any;
  iconBg: string;
}) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Flex
        w={8}
        h={8}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text margin={0} padding={0} fontWeight={600}>
        {text}
      </Text>
    </Stack>
  );
};

function Homepage() {
  const pageTitle = "The smarter way to check candidates references | Refcheka";
  const pageDescription =
    "Are you a hiring manager looking for a way to get employment reference checks done fast? Refcheka is the tool you need to do candidate reference checks quickly and accurately.";
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />

        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow" />
      </Head>
      <Header />
      <Box color="white" paddingTop={5}>
        <Container maxWidth="container.xl">
          <Box paddingTop={4} paddingX={2}>
            <Heading as="h1" size="xl">
              The smarter way to check candidate references
            </Heading>

            <Text fontSize="large" maxWidth="container.sm">
              Employment reference checks are time consuming. Using Refcheka,
              you can complete your checks at scale and in a fraction of the
              time.
            </Text>

            <Box paddingBottom={5}>
              <Button
                marginTop="4"
                href="/get-started"
                as={Link}
                bg="blue.900"
                _hover={{
                  backgroundColor: "blue.700",
                }}
                size="lg"
              >
                Start Free (No CC Required)
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box position="relative" bg="white">
        <Box position="absolute" width="100%" height="50%" bg="blue.500" />
        <Container position="relative" maxWidth="container.lg" paddingTop={10}>
          <Box
            borderRadius="md"
            shadow="lg"
            background="white"
            padding="1"
            width="100%"
          >
            <Image
              alt={"Dashboard showing candidates"}
              layout="responsive"
              src={heroImage}
              width="1475px"
              height="496px"
            />
          </Box>
        </Container>
      </Box>
      <Box bg="white">
        <Box paddingTop={14} paddingBottom={8} paddingX={2}>
          <Container maxWidth="container.xl">
            <Heading as="h2" size="lg">
              Features
            </Heading>

            <Flex
              justifyContent="center"
              flexDirection={["column", "column", "row"]}
              marginTop={3}
            >
              <Flex
                flexDirection="column"
                bg="white"
                flex="1"
                borderRadius="sm"
                marginY={2}
                marginX={2}
                padding={4}
              >
                <Flex alignItems="center" marginBottom="4">
                  <Box
                    borderRadius="100%"
                    bg="brown.100"
                    marginRight="2"
                    padding="3"
                  >
                    <Icon w={10} h={10} as={FcClock} />
                  </Box>
                  <Heading as="h3" size="md">
                    Time Saving
                  </Heading>
                </Flex>
                <Text>
                  Traditional reference checks can take up to 20 minutes. We
                  bring this down to 2 minutes.
                </Text>
              </Flex>
              <Flex
                flexDirection="column"
                bg="white"
                flex="1"
                borderRadius="sm"
                marginX={2}
                marginY={2}
                padding={4}
              >
                <Flex alignItems="center" marginBottom="4">
                  <Box
                    borderRadius="100%"
                    bg="brown.100"
                    marginRight="2"
                    padding="3"
                  >
                    <Icon w={10} h={10} as={FcLike} />
                  </Box>
                  <Heading as="h3" size="md">
                    Easy to Use
                  </Heading>
                </Flex>
                <Text>
                  Quick forms and mobile access make using Refcheka a breeze.
                </Text>
              </Flex>
              <Flex
                flexDirection="column"
                bg="white"
                flex="1"
                borderRadius="sm"
                marginY={2}
                marginX={2}
                padding={4}
              >
                <Flex alignItems="center" marginBottom="4">
                  <Box
                    borderRadius="100%"
                    bg="brown.100"
                    marginRight="2"
                    padding="3"
                  >
                    <Icon w={10} h={10} as={FcCollaboration} />
                  </Box>
                  <Heading as="h3" size="md">
                    Accountability
                  </Heading>
                </Flex>
                <Text>
                  Free up your time by allowing our automated reminders and
                  candidates to chase up the referees.
                </Text>
              </Flex>
            </Flex>

            <Flex
              flexDirection={["column", "column", "row"]}
              justifyContent="center"
            >
              <Flex
                marginX={2}
                marginY={2}
                flexDirection="column"
                bg="white"
                flex="1"
                borderRadius="sm"
                padding={4}
              >
                <Flex alignItems="center" marginBottom="4">
                  <Box
                    borderRadius="100%"
                    bg="brown.100"
                    marginRight="2"
                    padding="3"
                  >
                    <Icon w={10} h={10} as={FcLock} />
                  </Box>
                  <Heading as="h3" size="md">
                    Secure
                  </Heading>
                </Flex>
                <Text>
                  We use industry best practices to make sure data is safe. We
                  have tools to notify you of reference fraud or suspicious
                  activity.
                </Text>
              </Flex>
              <Flex
                padding={4}
                marginY={2}
                marginX={2}
                flexDirection="column"
                bg="white"
                flex="1"
                borderRadius="sm"
              >
                <Flex alignItems="center" marginBottom="4">
                  <Box
                    borderRadius="100%"
                    bg="brown.100"
                    marginRight="2"
                    padding="3"
                  >
                    <Icon w={10} h={10} as={FcApproval} />
                  </Box>
                  <Heading as="h3" size="md">
                    More Honesty
                  </Heading>
                </Flex>
                <Text>
                  Referees are more honest when completing a form compared to
                  speaking on the phone.
                </Text>
              </Flex>
              <Flex
                padding={4}
                marginX={2}
                marginY={2}
                flexDirection="column"
                bg="white"
                flex="1"
                borderRadius="sm"
              >
                <Flex alignItems="center" marginBottom="4">
                  <Box
                    borderRadius="100%"
                    bg="brown.100"
                    marginRight="2"
                    padding="3"
                  >
                    <Icon w={10} h={10} as={FcProcess} />
                  </Box>
                  <Heading as="h3" size="md">
                    Return time
                  </Heading>
                </Flex>
                <Text>
                  Standard reference checks take approximately 2-4 business days
                  to complete. With Refcheka and our automated reminders, most
                  checks will come back within 24 hours!
                </Text>
              </Flex>
            </Flex>
          </Container>
        </Box>
      </Box>

      <Container marginY={14} maxWidth="3xl">
        <Flex flexDirection="column" alignItems="center" color="white">
          <Flex alignItems="center">
            <Heading as="h5" size="lg">
              We reduce the time it takes to reference check a candidate from
              twenty minutes down to two minutes. That's a 400% improvement!
            </Heading>
            <Icon as={FcSportsMode} w={100} h={100} />
          </Flex>
        </Flex>
      </Container>

      <Box bg="white">
        <Container maxW={"5xl"} py={12}>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <Stack spacing={4}>
              <Heading as="h3">Questionnaire Builder</Heading>
              <Text color={"brown.800"} fontSize={"lg"}>
                Our questionnaire builder provides you with tools making it
                quick and easy to customise your templates.
              </Text>
              <Stack
                spacing={4}
                divider={<StackDivider borderColor={"gray.100"} />}
              >
                <Feature
                  icon={<Icon as={FcCheckmark} w={5} h={5} />}
                  iconBg="green.100"
                  text={"Unlimited customised questionnaires"}
                />
                <Feature
                  icon={<Icon as={FcCheckmark} w={5} h={5} />}
                  iconBg="green.100"
                  text={"Choose from a selection of answer types"}
                />
                <Feature
                  icon={<Icon as={FcCheckmark} w={5} h={5} />}
                  iconBg="green.100"
                  text={"Easily change the order of questions"}
                />
              </Stack>
            </Stack>
            <Flex width="100%">
              <Box
                borderRadius="md"
                shadow="lg"
                background="white"
                padding="1"
                width="100%"
              >
                <Image
                  alt={"Questionnaire Builder"}
                  layout="responsive"
                  src={questionnairesImage}
                  width="960px"
                  height="640px"
                />
              </Box>
            </Flex>
          </SimpleGrid>
        </Container>
      </Box>
      <Box bg="white">
        <Container maxW={"5xl"} py={12}>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <Flex width="100%">
              <Box
                borderRadius="md"
                shadow="lg"
                background="white"
                padding="1"
                width="100%"
              >
                <Image
                  alt={"Candidate form"}
                  layout="responsive"
                  src={candidateImage}
                  width="960px"
                  height="640px"
                />
              </Box>
            </Flex>
            <Stack spacing={4} order={[-1, -1, 1]}>
              <Heading as="h3">Candidate Management</Heading>
              <Text color={"brown.800"} fontSize={"lg"}>
                Create a candidate and an email will automatically be sent to
                them so they can provide their referees details.
              </Text>
              <Stack
                spacing={4}
                divider={<StackDivider borderColor={"gray.100"} />}
              >
                <Feature
                  icon={<Icon as={FcCheckmark} w={5} h={5} />}
                  iconBg="green.100"
                  text={"Create candidates in less than 30 seconds"}
                />
                <Feature
                  icon={<Icon as={FcCheckmark} w={5} h={5} />}
                  iconBg="green.100"
                  text={"Specify the amount of referees required"}
                />
                <Feature
                  icon={<Icon as={FcCheckmark} w={5} h={5} />}
                  iconBg="green.100"
                  text={
                    "Sit back and relax while the candidate, referees and Refcheka do the rest"
                  }
                />
              </Stack>
            </Stack>
          </SimpleGrid>
        </Container>
      </Box>
      <Box bg="white">
        <Container maxW={"5xl"} py={12}>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <Stack spacing={4}>
              <Heading as="h3">Reporting</Heading>
              <Text color={"brown.800"} fontSize={"lg"}>
                Once the references complete the questionnaire, you can login to
                view the report.
              </Text>
              <Stack
                spacing={4}
                divider={<StackDivider borderColor={"gray.100"} />}
              >
                <Feature
                  icon={<Icon as={FcCheckmark} w={5} h={5} />}
                  iconBg="green.100"
                  text={"Easily compare candidate and referee answers"}
                />
                <Feature
                  icon={<Icon as={FcCheckmark} w={5} h={5} />}
                  iconBg="green.100"
                  text={"View each reference in its own tab"}
                />
              </Stack>
            </Stack>
            <Flex width="100%">
              <Box
                borderRadius="md"
                shadow="lg"
                background="white"
                padding="1"
                width="100%"
              >
                <Image
                  alt={"Reporting"}
                  layout="responsive"
                  src={reportingImage}
                  width="960px"
                  height="640px"
                />
              </Box>
            </Flex>
          </SimpleGrid>
        </Container>
      </Box>
      <Container marginY={14} maxWidth="xl">
        <Flex flexDirection="column" alignItems="center" color="white">
          <Heading as="h4" size="lg" textAlign="center">
            Send your first reference check in under 5 minutes
          </Heading>
          <Text>
            Refcheka makes it easy to manage the reference checking process –
            which means you can spend more time on the things that matter.
          </Text>
          <Button
            marginTop={2}
            href="/get-started"
            as={Link}
            bg="blue.900"
            _hover={{
              backgroundColor: "blue.700",
            }}
          >
            Check your first candidate now
          </Button>
          <Text fontSize="md" color="white">
            No credit card required
          </Text>
        </Flex>
      </Container>
      <Box marginTop={8} bg="white">
        <Container maxWidth="container.xl">
          <NewsletterSignup />
        </Container>
      </Box>
      <Footer />
    </>
  );
}

export default Homepage;
