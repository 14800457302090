import { Heading, FormLabel, Box, Text, Button, Input } from "@chakra-ui/react";

export default function NewsletterSignup() {
  return (
    <Box paddingY={8} paddingX={2} textAlign="center">
      <Heading as="h3" size="lg">
        Get updates
      </Heading>
      <Text>
        Improve your hiring decisions, get insights into recruitment best
        practices and learn about our newest features.
      </Text>

      <Box>
        <form
          action="https://refcheka.us7.list-manage.com/subscribe/post?u=a2816800c6ff0fcdf98a42aaf&amp;id=d794833869&amp;f_id=0077cce4f0"
          method="post"
          name="mc-embedded-subscribe-form"
          target="_blank"
        >
          <Box marginTop="3">
            <FormLabel htmlFor="mce-EMAIL" textAlign="center">
              Email
            </FormLabel>
            <Input type="email" name="EMAIL" id="mce-EMAIL" />

            <Button
              mt="3"
              type="submit"
              bg="blue.900"
              color="white"
              _hover={{
                backgroundColor: "blue.700",
              }}
            >
              Subscribe now
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
